@import 'tailwind.css';
@import '~react-datepicker/dist/react-datepicker.css';
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.arrow-bottom::before {
  position: absolute;
  content: '';
  width: 0.71428571em;
  height: 0.71428571em;
  background: #fff;
  bottom: 0;
  -webkit-transform: rotate(45deg) translateY(60%);
  transform: rotate(45deg) translateY(60%);
  z-index: 2;
}
.arrow-top::before {
  position: absolute;
  content: '';
  width: 0.71428571em;
  height: 0.71428571em;
  background: #fff;
  top: 0;
  -webkit-transform: rotate(45deg) translateY(-60%);
  transform: rotate(45deg) translateY(-60%);
  z-index: 2;
}
.btn {
  border-radius: 5px;
  background-clip: padding-box;
  cursor: pointer;
  padding: 0.5rem 1rem;
}
.btn-primary {
  --bg-color-hover: var(--c-accent2-darkened);
  border-radius: 5px;
  background-clip: padding-box;
  cursor: pointer;
  padding: 0.5rem 1rem;
  @apply bg-primary;
  @apply text-white;
}
.btn-primary:hover {
  background-color: var(--bg-color-hover);
}
.center {
  @apply flex items-center justify-center;
}
.center-v {
  @apply flex items-center;
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.input {
  @apply rounded-lg border-gray-500 border py-2 px-4;
}
.input:focus {
  @apply outline-none;
}
.input\:hide-arrows::-webkit-inner-spin-button,
.input\:hide-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.group:hover .group-hover\:flex {
  display: flex;
}
.sidebar-dropdown-scroll::-webkit-scrollbar {
  background-color: var(--sidebar-dd-bg);
  width: 0.25rem;
}
.sidebar-dropdown-scroll::-webkit-scrollbar-thumb {
  background-color: var(--sidebar-font-dark);
}
.zoom {
  transition: 0.3s;
}
.zoom:hover {
  transform: scale(1.3);
}
.zoom-05 {
  transition: 0.3s;
}
.zoom-05:hover {
  transform: scale(1.05);
}
.zoom-1 {
  transition: 0.3s;
}
.zoom-1:hover {
  transform: scale(1.1);
}
.spin {
  animation: spin 2s linear infinite;
}
.spin-reverse {
  animation: spin 2s linear infinite reverse;
}
.ai-center {
  align-items: center;
}
.ai-start {
  align-items: start;
}
.as-stretch {
  align-self: stretch;
}
.h-content {
  height: calc(100vh - var(--header-height) - var(--screen-controls-height));
}
.max-h-half-content {
  max-height: calc((100vh - var(--header-height)) / 2);
}
.transition-03 {
  transition: 0.3s;
}
.w-content {
  width: calc(100vw - var(--sidebar-width));
}
html {
  line-height: 1.2;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
*,
*::before,
*::after {
  border-width: 0;
  border-style: solid;
  border-color: var(--border-color);
}
